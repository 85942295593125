import { BasicComponentProps } from "@/types"

export const H1Text = ({ children, className }: BasicComponentProps) => {
  return (
    <h1
      className={`text-3xl text-white text-center font-bold mb-1 ${
        className ? className : ""
      }`}
    >
      {children}
    </h1>
  )
}

export const Text = ({ children, className }: BasicComponentProps) => (
  <p className={`text-sm text-zinc-400 max-w-md ${className ? className : ""}`}>
    {children}
  </p>
)
