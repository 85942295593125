export const CloseButton = ({ className }: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={`text-white hover:text-pink-500 transition ${
        className || "h-8 w-8"
      }`}
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path d="M6 18L18 6M6 6l12 12" strokeWidth="2" />
    </svg>
  )
}
